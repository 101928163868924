import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import Layout from "../layout";
import MobileHeader from "../components/MobileHeader";
import useUser from "../../../hooks/useUser";
import BASE_URL from "../../../services";
import dp from "../../../assets/images/referpic.png";

const Referral = () => {
  const { user } = useUser();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const referralLinkRef = useRef(null);

  const copyReferralLink = () => {
    if (referralLinkRef.current) {
      referralLinkRef.current.select();
      document.execCommand("copy");
      setSuccessMessage("Referral link copied to clipboard!");
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    }
  };

  const handleSendInvite = async () => {
    setIsSending(true);
    try {
      const response = await axios.post(BASE_URL + `/auth/send-referral`, {
        email: email,
        fullName: user?.name,
        message: message,
      });
      // console.log(response);
      setSuccessMessage("Invite sent successfully.");
      setTimeout(() => {
        setSuccessMessage(null);
      }, 7500);
      setEmail("");
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setTimeout(() => {
        setErrorMessage(null);
      }, 7500);
      setIsSending(false);
      console.error(error);
    }
  };

  // useEffect(() => {
  //   const fetchContacts = async () => {
  //     try {
  //       const response = await axios.get(
  //         BASE_URL + `/auth/google/fetch-contacts?accessToken=${user.token}`
  //       );
  //       setContacts(response.data);
  //       console.log("the contacts" + response);
  //     } catch (error) {
  //       console.error("Error fetching contacts:", error);
  //     }
  //   };

  //   fetchContacts();
  // }, []);


  return (
    <>
      {isTabletOrMobile ? (
        <div className="bg-[#FAFBFD] px-2 py-14 h-screen">
          <MobileHeader />
          <>
            <div className="">
              <h1 className="changa-bold text-xl mb-[2px] text-[#454488]">
                Help build the HelpAI community!
              </h1>
              <p className="font-normal text-base text-[#999999]">
                Invite your friends, earn 3 Likes when they sign up, and 3 more
                when they invite others.
              </p>
            </div>

            <div className="mt-[70px]">
              <div className="flex gap-2 items-center mb-4">
                <div>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Enter email address"
                    className="rounded-lg bg-white border-gray-200 border h-12 cursor-text flex items-center px-6 p-1 focus:ring-white"
                  />
                </div>
                <div>
                  <button
                    onClick={handleSendInvite}
                    className="bg-[#414080] px-5 py-2.5 rounded-lg text-white"
                    disabled={isSending}
                  >
                    {isSending ? "Sending..." : "Send"}
                  </button>
                </div>
              </div>

              <div className="flex gap-2 items-center mb-[50px]">
                <div className="truncate-refereal-link">
                  <input
                    ref={referralLinkRef}
                    readOnly
                    value={user?.referralLink}
                    className="rounded-lg bg-[#4140800F] border-gray-200 border h-12 cursor-text flex items-center px-6 p-1 truncate text-[#6C6BD5]"
                  />
                </div>
                <div>
                  <button
                    onClick={copyReferralLink}
                    className="bg-transparent border border-[#414080] px-5 py-2.5 rounded-lg text-[#414080]"
                  >
                    Copy
                  </button>
                </div>
              </div>

              <div className="h-[286px] shadow rounded-lg p-5">
                <div className="mb-1">
                  <h1 className="mb-1 text-[#414080] changa-semibold text-xl">
                    Write a message for your invites
                  </h1>
                  <p className="text-[#999999]">
                    The message below will be sent to your invites
                  </p>
                </div>
                <div className="">
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="w-full p-3 focus:ring-white border border-gray-200 rounded-lh"
                    name=""
                    placeholder="Type something here..."
                  ></textarea>
                </div>
              </div>
            </div>
          </>
          <AnimatePresence>
            {errorMessage && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
              >
                <p>{errorMessage}</p>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {successMessage && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
              >
                <p>{successMessage}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <Layout>
          <div className="px-9 py-5 w-full">
            <div className="">
              <h1 className="changa-bold text-xl mb-[2px] text-[#454488]">
                Help build the HelpAI community!
              </h1>
              <p className="font-normal text-base text-[#999999]">
                Invite your friends, earn 3 Likes when they sign up, and 3 more
                when they invite others.
              </p>
            </div>

            <div className="mt-[70px]">
              <div className="flex gap-2 items-center mb-4">
                <div>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Enter email address"
                    className="w-[380px] rounded-lg bg-white border-gray-200 border h-12 cursor-text flex items-center px-6 p-1 focus:ring-white"
                  />
                </div>
                <div>
                  <button
                    onClick={handleSendInvite}
                    className="bg-[#414080] px-5 py-2.5 rounded-lg text-white"
                    disabled={isSending}
                  >
                    {isSending ? "Sending Invite..." : "Send Invite"}
                  </button>
                </div>
              </div>

              <div className="flex gap-2 items-center mb-[50px]">
                <div className="truncate-refereal-link">
                  <input
                    ref={referralLinkRef}
                    readOnly
                    value={user?.referralLink}
                    className="w-[380px] rounded-lg bg-[#4140800F] border-gray-200 border h-12 cursor-text flex items-center px-6 p-1 truncate text-[#6C6BD5]"
                  />
                </div>
                <div>
                  <button
                    onClick={copyReferralLink}
                    className="bg-[#414080]bg-transparent border border-[#414080] px-5 py-2.5 rounded-lg text-[#414080]"
                  >
                    Copy Invite link
                  </button>
                </div>
              </div>

              <div className="h-[286px] shadow rounded-lg p-5 w-[577px] mb-10">
                <div className="mb-1">
                  <h1 className="mb-1 text-[#414080] changa-semibold text-xl">
                    Write a message for your invites
                  </h1>
                  <p className="text-[#999999]">
                    The message below will be sent to your invites
                  </p>
                </div>
                <div className="">
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="w-full p-3 focus:ring-white border border-gray-200 rounded-lh"
                    name=""
                    placeholder="Type something here..."
                  ></textarea>
                </div>
              </div>
              {/* <div className="mt-5">
                <div className="mt-5">
                    <ContactCard key={index} contact={contact} />
                </div>
              </div> */}
            </div>
          </div>
          <>
            <AnimatePresence>
              {errorMessage && (
                <motion.div
                  initial={{ opacity: 0, x: "100%" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100%" }}
                  transition={{ duration: 0.3 }}
                  className="text-white md:text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
                >
                  <p>{errorMessage}</p>
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {successMessage && (
                <motion.div
                  initial={{ opacity: 0, x: "100%" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100%" }}
                  transition={{ duration: 0.3 }}
                  className="text-white md:text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
                >
                  <p>{successMessage}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </>
        </Layout>
      )}
    </>
  );
};

export default Referral;

const ContactCard = () => {
  return (
    <div className="w-[215px] h-[230px] bg-white border border-[#F5F5F5] px-3 py-7 rounded-lg">
      <div className="flex justify-center items-center mb-5">
        <img src={dp} alt="user dp" />
      </div>
      <div className="mb-2">
        <h1 className="text-center changa-semibold text-[#454488]">
          Obinna Ikechukwu
        </h1>
        <p className="text-center overflow-hidden text-ellipsis whitespace-nowrap">
          obinnaiyke@gmail.com
        </p>
      </div>
      <div>
        <button className="px-4 py-2 border border-[#414080] w-full rounded-lg text-[#414080]">
          Invite
        </button>
      </div>
    </div>
  );
};
