import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import googlelogo from '../assets/images/Google logo.svg';
import email from '../assets/images/outline-email.svg';
import closeSvg from '../assets/images/Vector Close.svg';

const Modal = ({ closeModal }) => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	return (
		<>
			{isTabletOrMobile ? (
				<>
					<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 changa'>
						<div className='bg-white p-8 rounded-lg mt-5'>
							<div className='py-20 px-16 bg-white rounded-lg relative sm:px-20'>
								<button
									className='absolute top-5 right-7'
									onClick={closeModal}>
									<img
										src={closeSvg}
										alt='Close Icon'
									/>
								</button>
								<div>
									<div className='text-center mb-9'>
										<h2 className='mb-1 text-2xl leading-[44px] font-semibold'>Sign up</h2>
										<p className='w-full mx-auto'>
											Fill in your information so we can provide you with you the best service possible
										</p>
									</div>

									<div className='flex flex-col items-center'>
										<a
											href='https://helpai-backend-production.onrender.com/auth/google'
											target='_blank'>
											<button className='inputbox w-[300px] py-3 flex justify-center items-center space-x-4 mb-7 text-lg font-semibold border-2 border-[#CFCFCF]'>
												<div>
													<img
														className='w-5 h-5'
														src={googlelogo}
														alt='Google Logo'
													/>
												</div>
												<div>
													<p>Sign up with Google</p>
												</div>
											</button>
										</a>
										<Link to='/create-account'>
											<button className='inputbox w-[300px] py-3 flex justify-center items-center space-x-4 mb-[29px] text-lg text-white bg-[#414080]'>
												<div>
													<img
														src={email}
														alt='Email Icon'
													/>
												</div>
												<div>
													<p>Sign up with Email</p>
												</div>
											</button>
										</Link>
									</div>

									<div className='flex justify-center space-x-2 mb-10 text-lg leading-[33px] tracking-[.0.25em]'>
										<p className='text-[#5F5F5F]'>Already have an account? </p>
										<Link
											to='/login'
											className='text-[#5655AA] font-semibold'>
											Sign in
										</Link>
									</div>
									<div className='max-w-[300px] text-sm text-center mx-auto'>
										<p className='text-[#5F5F5F]'>
											By signing up to create an account I accept HelpAI’s{' '}
											<Link
												to='/'
												className='text-[#6C6BD4]'>
												Term’s of use and Privacy Policy
											</Link>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 changa'>
					<div className='bg-white p-8 rounded-lg'>
						<div className='py-20 px-16 bg-white rounded-lg relative sm:px-20'>
							<button
								className='absolute top-5 right-7'
								onClick={closeModal}>
								<img
									src={closeSvg}
									alt='Close Icon'
								/>
							</button>
							<div>
								<div className='text-center mb-9'>
									<h2 className='mb-1 text-2xl leading-[44px] font-semibold'>Sign up</h2>
									<p className='max-w-[300px] mx-auto'>
										Fill in your information so we can provide you with you the best service possible
									</p>
								</div>

								<div className='flex flex-col items-center'>
									<a
										href='https://helpai-backend-production.onrender.com/auth/google'
										target='_blank'>
										<button className='inputbox w-[313px] py-3 flex justify-center items-center space-x-4 mb-7 text-lg font-semibold border-2 border-[#CFCFCF]'>
											<div>
												<img
													className='w-5 h-5'
													src={googlelogo}
													alt='Google Logo'
												/>
											</div>
											<div>
												<p>Sign up with Google</p>
											</div>
										</button>
									</a>
									<Link to='/create-account'>
										<button className='inputbox w-[313px] py-3 flex justify-center items-center space-x-4 mb-[29px] text-lg text-white bg-[#414080]'>
											<div>
												<img
													src={email}
													alt='Email Icon'
												/>
											</div>
											<div>
												<p>Sign up with Email</p>
											</div>
										</button>
									</Link>
								</div>

								<div className='flex justify-center space-x-2 mb-10 text-lg leading-[33px] tracking-[.0.25em]'>
									<p className='text-[#5F5F5F]'>Already have an account? </p>
									<Link
										to='/login'
										className='text-[#5655AA] font-semibold'>
										Sign in
									</Link>
								</div>
								<div className='max-w-[300px] text-sm text-center mx-auto'>
									<p className='text-[#5F5F5F]'>
										By signing up to create an account I accept HelpAI’s{' '}
										<Link
											to='/'
											className='text-[#6C6BD4]'>
											Term’s of use and Privacy Policy
										</Link>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Modal;
