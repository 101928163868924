import { useMediaQuery } from 'react-responsive';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';

import Layout from './layout.jsx';
import MobileHeader from './components/MobileHeader.jsx';
import search from '../../assets/images/searchnormal1.svg';
import initialmessagemobile from '../../assets/images/initialmessagemobile.svg';
import backchat from '../../assets/images/backchat.svg';
import BASE_URL from '../../services/index.js';
import { useProfileData } from '../../services/getProfileService';
import useUser from '../../hooks/useUser.jsx';
import { Spinner } from './components/Spinner';

const Message = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const [connections, setConnections] = useState([]);
	const [loading, setLoading] = useState(true);
	const [logicError, setLogicError] = useState();
	const [currentChatUser, setCurrentChatUser] = useState('');
	const { user } = useUser();
	const [isChatModalOpen, setIsChatModalOpen] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(BASE_URL + '/account/my-connections', {
					headers: { Authorization: `Bearer ${user.token}` },
				});

				const data = response.data;
				setConnections(data.data || []);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
				setLogicError(error.response?.data?.Message || 'An error occurred.');
				setTimeout(() => {
					setLogicError('');
				}, 8000);
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	const handleUserClick = (e) => {
		setCurrentChatUser(e);
		if (isTabletOrMobile) {
			setIsChatModalOpen(true);
		}
	};

	const closeModal = () => {
		setIsChatModalOpen(false);
	};

	return (
		<>
			{isTabletOrMobile ? (
				<div className='bg-[#FAFBFD] px-2 py-14 h-screen'>
					<MobileHeader />
					<div>
						<div className='mb-5'>
							<h1 className='changa-bold text-xl mb-[2px] text-[#454488]'>Messages</h1>
						</div>
						<div className='rounded-lg bg-white h-12 cursor-text flex items-center px-6 gap-5 mb-9'>
							<div>
								<img
									src={search}
									alt=''
								/>
							</div>
							<div>
								<input
									type='text'
									placeholder='Search'
									className='p-1 outline-none focus:ring-white border border-white w-[300px]'
								/>
							</div>
						</div>
						<div>
							{loading ? (
								<div className='flex justify-center items-center'>
									<Spinner />
								</div>
							) : logicError ? (
								<p>Error: {logicError}</p>
							) : connections.length === 0 ? (
								<div className='flex justify-center items-center'>
									<img
										src={initialmessagemobile}
										alt=''
									/>
								</div>
							) : (
								<div>
									{connections.map((connection, index) => (
										<MobileUserBox
											key={index}
											connection={connection}
											onClick={(e) => handleUserClick(connection)}
										/>
									))}
								</div>
							)}
						</div>
					</div>
					{isChatModalOpen && (
						<div className='fixed top-0 left-0 w- h-full bg-black bg-opacity-50 flex justify-center items-center'>
							<div className='bg-white p-5 h-screen w-screen'>
								<button
									className='absolute top-10 left-3'
									onClick={closeModal}>
									<img
										src={backchat}
										alt=''
									/>
								</button>
								<ChatContainer currentChatUser={currentChatUser} />
							</div>
						</div>
					)}
				</div>
			) : (
				<Layout>
					<div className='flex'>
						<div className='h-screen px-9 border-r-1 border'>
							<div className='mt-4 mb-6'>
								<h1 className='changa-bold text-xl text-[#0F0F0F] text-[#454488]'>Messages</h1>
							</div>
							<div>
								<div className='rounded-lg bg-white h-12 cursor-text flex items-center px-6 gap-5 mb-9'>
									<div>
										<img
											src={search}
											alt=''
										/>
									</div>
									<div>
										<input
											type='text'
											placeholder='Search'
											className='p-1 outline-none'
										/>
									</div>
								</div>
							</div>

							<div>
								{loading ? (
									<div className='flex justify-center items-center'>
										<Spinner />
									</div>
								) : logicError ? (
									<p>An error occured - {logicError}</p>
								) : connections.length === 0 ? (
									<div className='flex justify-center items-center'>
										<img
											src={initialmessagemobile}
											alt=''
										/>
									</div>
								) : (
									<div>
										{connections.map((connection, index) => (
											<UserBox
												onClick={(e) => handleUserClick(connection)}
												key={index}
												connection={connection}
											/>
										))}
									</div>
								)}
							</div>
						</div>
						<div className='px-5 py-5'>
							{currentChatUser !== '' ? (
								<ChatContainer currentChatUser={currentChatUser} />
							) : (
								<div className='flex justify-center items-center'>
									<div>Click on user to start messaging</div>
								</div>
							)}
						</div>
					</div>
				</Layout>
			)}
		</>
	);
};

export default Message;

const MobileUserBox = ({ connection, onClick }) => {
	return (
		<div
			onClick={onClick}
			className='bg-white shadow rounded w-full px-5 py-4 flex justify-between items-center mb-3 cursor-pointer'>
			<div className='flex gap-2 items-center'>
				<div>
					<img
						className='rounded-full w-[39px] h-[39px]'
						src={connection.profileImage}
						alt=''
					/>
				</div>
				<div>
					<p className='text-[#0F0F0F] font-semibold text-md'>{connection.fullName}</p>
				</div>
			</div>

			<div>
				<div className='bg-[#5655AA] w-[8px] h-[8px] rounded-full'></div>
			</div>
		</div>
	);
};

const UserBox = ({ connection, onClick }) => {
	return (
		<div
			onClick={onClick}
			className='bg-white shadow rounded w-full px-5 py-4 flex justify-between items-center mb-3 cursor-pointer'>
			<div className='flex gap-2 items-center'>
				<div>
					<img
						className='rounded-full w-[39px] h-[39px]'
						src={connection.profileImage}
						alt=''
					/>
				</div>
				<div>
					<p className='text-[#0F0F0F] font-semibold text-md'>{connection.fullName}</p>
				</div>
			</div>

			<div>
				<div className='bg-[#5655AA] w-[8px] h-[8px] rounded-full'></div>
			</div>
		</div>
	);
};

export const ChatContainer = ({ currentChatUser }) => {
	const [message, setMessage] = useState();
	const [chats, setChats] = useState([]);
	const scrollRef = useRef();
	const { profileData } = useProfileData();
	const [arrivalMessage, setArrivalMessage] = useState(null);
	const { user } = useUser();
	const [socket, setSocket] = useState(null);

	const handleChange = (e) => {
		setMessage(e.target.value);
	};

	const getChats = async () => {
		try {
			const response = await axios.get(BASE_URL + `/message/get/message/${currentChatUser?._id}`, {
				headers: { Authorization: `Bearer ${user.token}` },
			});
			setChats(response?.data?.data);
			console.log(response?.data?.data);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getChats();
		console.log('Fetching chats for user:', currentChatUser?._id);
	}, [currentChatUser?._id]);

	useEffect(() => {
		scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
	}, [chats]);

	useEffect(() => {
		if (currentChatUser !== '') {
			const newSocket = io(BASE_URL);
			setSocket(newSocket);
			newSocket.emit('user-is-online', currentChatUser._id);
			return () => newSocket.disconnect();
		}
	}, [currentChatUser._id]);

	const sendMessage = (e) => {
		e.preventDefault();

		const newMessage = {
			myself: true,
			message: message,
		};
		console.log(newMessage);

		const requestParameters = {
			from: profileData?.data?._id,
			to: currentChatUser?._id,
			message: message,
		};

		socket.emit('private-message', {
			senderUserId: profileData?.data?._id,
			receiverUserId: currentChatUser?._id,
			message: message,
		});

		setChats([...chats, newMessage]);
		setMessage('');
	};

	useEffect(() => {
		if (socket) {
			socket.on('private-message', (msg) => {
				setArrivalMessage({ myself: false, message: msg });
			});
		}
	}, [socket]);

	useEffect(() => {
		if (arrivalMessage) {
			setChats((prev) => [...prev, arrivalMessage]);
		}
	}, [arrivalMessage]);

	return (
		<>
			<div className=''>
				<div className='p-1 flex items-center gap-2 mb-5'>
					<div>
						<img
							className='w-[50px] h-[50px] rounded-full'
							src={currentChatUser?.profileImage}
							alt={`${currentChatUser?.fullName}'s Profile`}
						/>
					</div>
					<div>
						<p
							onClick={getChats}
							className='text-[#0F0F0F] font-semibold text-md'>
							{currentChatUser?.fullName}
						</p>
					</div>
				</div>

				<hr />

				<div className='h-[700px] md:h-[590px] 2xl:h-[700px] overflow-y-auto md:w-[700px] 2xl:w-[800px] pb-10 mt-5'>
					{chats?.map((item, index) => (
						<div
							ref={scrollRef}
							key={index}>
							{item.myself === false ? (
								<div className='bg-[#5655AA] px-3 py-2 md:px-5 md:py-2 text-white rounded-tr-xl rounded-br-xl rounded-bl-xl mb-1 md:max-w-md flex gap-3 items-center w-[300px] md:w-full'>
									<div>
										<img
											className='w-[30px] h-[30px] rounded-full'
											src={currentChatUser?.profileImage}
											alt={`${currentChatUser?.fullName}'s Profile picture`}
										/>
									</div>
									<div>
										<p>{item?.message}</p>
									</div>
								</div>
							) : (
								<div className='flex justify-end'>
									<div className='bg-[#ECEFF6] text-[#555555] px-3 py-2 md:px-5 md:py-2 rounded-br-xl rounded-bl-xl rounded-tl-xl mb-1 md:max-w-md '>
										<p className='text-start'>{item?.message}</p>
									</div>
								</div>
							)}
						</div>
					))}
				</div>

				<div className='absolute bottom-10 w-screen md:w-[400px] 2xl:w-[690px]'>
					<form
						className='flex justify-between gap-2 items-center'
						onSubmit={sendMessage}>
						<div>
							<input
								type='text'
								className='w-[270px] md:w-[600px] 2xl:w-[700px] bg-white px-6 py-3 border border-[#CFCFCF] rounded-xl outline-none'
								placeholder='Enter your message here'
								value={message}
								onChange={handleChange}
							/>
						</div>
						<div>
							<button
								type='submit'
								className='bg-[#5655AA] px-3 py-3 rounded-lg w-24 text-white mr-10 md:mr-0'>
								Send
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

// const [message, setMessage] = useState('');
// const [chats, setChats] = useState([]);
// const scrollRef = useRef();
// const socket = useRef();
// const { profileData } = useProfileData();
// const [arrivalMessage, setArrivalMessage] = useState(null);
// const { user } = useUser();

// // console.table(profileData)

// const handleChange = (e) => {
// 	setMessage(e.target.value);
// };

// const getChats = async () => {
// 	try {
// 		const response = await axios.get(BASE_URL + `/message/get/message/${currentChatUser?._id}`, {
// 			headers: { Authorization: `Bearer ${user.token}` },
// 		});
// 		setChats(response?.data?.data);
// 		console.log(response?.data?.data);
// 	} catch (error) {
// 		console.error(error);
// 	}
// };

// useEffect(() => {
// 	scrollRef.current?.scrollIntoView({ behaviour: 'smooth' });
// }, [chats]);

// useEffect(() => {
// 	if (currentChatUser !== '') {
// 		socket.current = io(BASE_URL);
// 		socket.current.emit('user-is-online', currentChatUser._id);
// 	}
// }, [currentChatUser._id]);

// console.log(socket);

// const sendMessage = (e) => {
// 	e.preventDefault();

// 	const messagess = {
// 		myself: true,
// 		message: message,
// 	};

// 	const requestParameters = {
// 		from: profileData?.data?._id,
// 		to: currentChatUser?._id,
// 		message: message,
// 	};
// 	console.log(requestParameters);

// 	socket.current.emit('message', {
// 		senderUserId: profileData?.data?._id,
// 		receiverUserId: currentChatUser?._id,
// 		message: message,
// 	});
// };

// useEffect(() => {
// 	getChats();
// }, [currentChatUser?._id]);

// useEffect(() => {
// 	if (socket.current) {
// 		socket.current.on('message-received', (msg) => {
// 			setArrivalMessage([{ myself: false, message: msg }]);
// 		});
// 	}
// }, [arrivalMessage]);

// useEffect(() => {
// 	arrivalMessage && setChats((prev) => [...prev, arrivalMessage]);
// }, [arrivalMessage]);
