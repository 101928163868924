import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

import Layout from '../layout.jsx';

import MobileHeader from '../components/MobileHeader.jsx';
import edit from '../../../assets/images/edit.svg';
import alert from '../../../assets/images/alert.svg';
import closeSvg from '../../../assets/images/Vector Close.svg';
import BASE_URL from '../../../services/index.js';
import { useAuth } from '../../../hooks/useAuthentication';
import useUser from '../../../hooks/useUser.jsx';

const Profile = () => {
	const { user } = useUser();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const [profile, setProfile] = useState({
		profilepic: null,
		name: '',
		location: '',
		elevatorpitch: '',
		industry: '',
		skills: {
			skill1: '',
			skill2: '',
			skill3: '',
		},
		portfolio: '',
		resume: '',
		phoneNumber: '',
	});
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isCancelModalOpen, setisCancelModalOpen] = useState(false);
	const [isDataFetched, setIsDataFetched] = useState(false);

	const [editMode, setEditMode] = useState(false);
	const [editedName, setEditedName] = useState('');
	const [editedPhoneNumber, setEditedPhoneNumber] = useState('');
	const [editedLocation, setEditedLocation] = useState('');
	const [editedElevatorPitch, setEditedElevatorPitch] = useState('');
	const [editedIndustry, setEditedIndustry] = useState('');
	const [editedPortfolio, setEditedPortfolio] = useState('');
	const [editedResume, setEditedResume] = useState('');
	const [response, setResponse] = useState();
	const [error, setError] = useState();
	const navigate = useNavigate();
	const { logout } = useAuth();
	// console.log(user);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(BASE_URL + '/account/settings/profile', {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				});
				const data = response.data.data;
				// console.log(data);

				setProfile({
					profilepic: data.profileImage,
					name: data.fullName,
					email: data.email,
					location: data.userLocation,
					elevatorpitch: data.elevatorPitch,
					industry: data.industry,
					skills: {
						skill1: data.skillDemanded[0] || '',
						skill2: data.skillDemanded[1] || '',
						skill3: data.skillDemanded[2] || '',
					},
					portfolio: data.portfolio,
					resume: data.resume,
					phoneNumber: data.phoneNumber,
				});
				setIsDataFetched(true);
			} catch (error) {
				console.error('Error fetching profile data:', error.response.data.Message);
				setIsDataFetched(true);
			}
		};

		fetchData();
	}, []);

	const handleDeleteAccountClick = () => {
		setIsDeleteModalOpen(true);
	};

	const handleCancelAccountClick = () => {
		navigate('/cancel-subscription');
	};

	const handleCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const handleCloseCancelModal = () => {
		setisCancelModalOpen(false);
	};

	const handleDeleteAccount = async () => {
		try {
			await axios.delete(BASE_URL + '/account/deletemyaccount', {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			});
			// setResponse('Your account have been deleted successfully');
			logout();
			navigate('/delete-success');
		} catch (error) {
			console.error('Error deleting account:', error.response.data.Message);
			setError(error.response.data.Message);
			setTimeout(() => {
				setError('');
			}, 8000);
		}
	};

	const handlePersonalEdit = () => {
		navigate('/edit-personal-information');
	};

	const handleProfessionalEdit = () => {
		navigate('/edit-professional-information');
	};

	return (
		<>
			{isTabletOrMobile ? (
				<div className='bg-[#FAFBFD] px-2 py-14 h-auto'>
					<MobileHeader />
					<>
					<div className='py-2 px-2 md:w-[800px] overflow-y-auto h-screen'>
						<div className='mb-16'>
							<h1 className='changa-bold text-xl capitalize mb-[2px] text-[#454488]'>Your Profile</h1>
						</div>
						<div>
							<div className='flex items-center gap-6 mb-16'>
								<div>
									{isDataFetched ? (
										<img
											className='w-[90px] h-[90px] rounded-full object-cover'
											src={profile.profilepic}
											alt=''
										/>
									) : (
										<Skeleton
											circle={true}
											width={90}
											height={90}
										/>
									)}
								</div>
								<div>
									{/* <h1 className='changa-bold mb-3'>Profile</h1> */}
									{/* <p>Update your photo and personal details</p> */}
								</div>
							</div>
							<hr />
							<div className='mt-11'>
								<div className='flex items-center gap-4'>
									<div>
										<h2 className='font-semibold text-xl'>Personal Information</h2>
									</div>
									<div>
										<div
											onClick={handlePersonalEdit}
											className='px-3 py-1 rounded-2xl bg-white flex items-center gap-2 border border-gray-300 cursor-pointer'>
											<div>
												<img
													src={edit}
													alt=''
												/>
											</div>
											<div>
												<p>Edit</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='md:grid md:grid-cols-2 md:grid-row-2 mt-2 mb-11'>
								<div className='mb-5'>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Full Name</h3>
									{isDataFetched ? (
										<>
											<p>{profile.name}</p>
										</>
									) : (
										<Skeleton width={150} />
									)}
								</div>
								<div className='mb-5'>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Email address</h3>
									{isDataFetched ? <p>{profile.email}</p> : <Skeleton width={190} />}
								</div>
								<div className='mb-5'>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Phone number</h3>
									{isDataFetched ? (
										<>
											<p>{profile.phoneNumber}</p>
										</>
									) : (
										<Skeleton width={120} />
									)}
								</div>
								<div className='mb-5'>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Location</h3>
									{isDataFetched ? (
										<>
											<p>{profile.location}</p>
										</>
									) : (
										<Skeleton width={130} />
									)}
								</div>
							</div>

							<hr />

							<div className='mt-11'>
								<div className='flex items-center gap-4'>
									<div>
										<h2 className='font-semibold text-xl'>Professional Information</h2>
									</div>
									<div>
										<div
											onClick={handleProfessionalEdit}
											className='px-3 py-1 rounded-2xl bg-white flex items-center gap-2 border border-gray-300 cursor-pointer'>
											<div>
												<img
													src={edit}
													alt=''
												/>
											</div>
											<div>
												<p>Edit</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='mt-2'>
								<div>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Elevator Pitch</h3>
									{isDataFetched ? (
										<>
											<p className='text-[#373737] text-normal font-[500]'>{profile.elevatorpitch}</p>
										</>
									) : (
										<>
											<Skeleton width={650} />
											<Skeleton width={650} />
											<Skeleton width={650} />
											<Skeleton width={650} />
											<Skeleton width={250} />
										</>
									)}
								</div>

								<div className='mt-6'></div>
								<h3 className='text-[#5F5F5F] text-sm mb-1'>Skills</h3>
								<div className='flex gap-2'>
									<div className='w-auto px-4 py-1 bg-white border border-gray-300 capitalize rounded-md font-[500]'>
										{isDataFetched ? <p>{profile.skills.skill1}</p> : <Skeleton width={130} />}
									</div>
									<div className='w-auto px-4 py-1 bg-white border border-gray-300 capitalize rounded-md font-[500]'>
										{isDataFetched ? <p>{profile.skills.skill2}</p> : <Skeleton width={130} />}
									</div>
									<div className='w-auto px-4 py-1 bg-white border border-gray-300 capitalize rounded-md font-[500]'>
										{isDataFetched ? <p>{profile.skills.skill3}</p> : <Skeleton width={130} />}
									</div>
								</div>
							</div>

							<div className='mt-6'>
								<h3 className='text-[#5F5F5F] text-sm mb-1'>Industry</h3>
								{isDataFetched ? (
									<>
										<p>{profile.industry}</p>
									</>
								) : (
									<Skeleton width={100} />
								)}
							</div>

							<div className='mt-6'>
								<h3 className='text-[#5F5F5F] text-sm mb-1'>Portfolio</h3>

								{isDataFetched ? (
									<>
										<a
											href={profile.portfolio}
											target='_blank'>
											<p className='text-purple-500 text-normal font-[500]'>{profile.portfolio}</p>
										</a>
									</>
								) : (
									<Skeleton width={200} />
								)}
							</div>

							<div className='mt-6 mb-11'>
								<h3 className='text-[#5F5F5F] text-sm mb-1'>Resume</h3>
								{isDataFetched ? (
									<a
										href={profile.resume}
										target='_blank'>
										<p className='text-normal font-[500] text-purple-500'>{profile.name}'s resume</p>
									</a>
								) : (
									<Skeleton width={100} />
								)}
							</div>

							<hr />

							<div className='flex gap-5 items-center'>
								{user?.tier === 'Free' ? null : (
									<div className='mt-6'>
										<p
											onClick={handleCancelAccountClick}
											className='text-[#EA4335] font-bold text-lg cursor-pointer bg-transparent border border-[#CFCFCF] py-2 px-4 rounded-lg'>
											Cancel subscription
										</p>
									</div>
								)}
								<div className='mt-6'>
									<p
										onClick={handleDeleteAccountClick}
										className='text-[#fff] font-bold text-lg cursor-pointer bg-[#414080] py-2 px-4 rounded-lg'>
										Delete your Account
									</p>
								</div>
							</div>
						</div>

						{isCancelModalOpen && (
							<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10'>
								<div className='bg-white p-6 rounded-lg shadow-lg relative'>
									<button
										className='absolute right-7 top-5'
										onClick={handleCloseCancelModal}>
										<img
											src={closeSvg}
											alt='Close Icon'
										/>
									</button>
									<h2 className='text-xl font-semibold mb-4 mt-7'>We’re so sorry to see you go</h2>
									<p>
										By cancelling your subscription, your account,
										<br /> your payment subscription bundle and all it offers will be terminated
										<br /> an will be permanently removed from our database.
									</p>
									<div className='bg-[#FEE9ED] px-3 py-2 flex gap-2 mt-7 rounded-md'>
										<div>
											<img
												src={alert}
												alt='sign'
											/>
										</div>
										<div>
											<p>
												Canceling subscription is final and cannot be undone. We will not
												<br /> be able to restore your account
											</p>
										</div>
									</div>
									<div className='mt-9'>
										<button className='px-4 py-2 bg-red-500 text-white rounded-md w-full'>Cancel Subscription</button>
									</div>
								</div>
							</div>
						)}

						{isDeleteModalOpen && (
							<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10'>
								<div className='bg-white p-6 rounded-lg shadow-lg relative'>
									<button
										className='absolute right-7 top-5'
										onClick={handleCloseDeleteModal}>
										<img
											src={closeSvg}
											alt='Close Icon'
										/>
									</button>
									<h2 className='text-xl font-semibold mb-4 mt-7'>We’re so sorry to see you go</h2>
									<p>
										By deleting your account, all your data, including your profile, personal
										<br /> information, history, and saved settings, will be permanently removed
										<br /> from our database.
									</p>
									<div className='bg-[#FEE9ED] px-3 py-2 flex gap-2 mt-7 rounded-md'>
										<div>
											<img
												src={alert}
												alt='sign'
											/>
										</div>
										<div>
											<p>
												Account deletion is final and cannot be undone. We will not
												<br /> be able to restore your account
											</p>
										</div>
									</div>
									<div className='mt-9'>
										<button
											onClick={handleDeleteAccount}
											className='px-4 py-2 bg-red-500 text-white rounded-md w-full'>
											Delete Account
										</button>
									</div>
								</div>
							</div>
						)}
						<AnimatePresence>
							{response && (
								<motion.div
									initial={{ opacity: 0, x: '100%' }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0, x: '100%' }}
									transition={{ duration: 0.3 }}
									className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg z-10'>
									<p>{response}</p>
								</motion.div>
							)}
						</AnimatePresence>
						<AnimatePresence>
							{error && (
								<motion.div
									initial={{ opacity: 0, x: '100%' }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0, x: '100%' }}
									transition={{ duration: 0.3 }}
									className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg z-10'>
									<p>{error}</p>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
					</>
				</div>
			) : (
				<Layout>
					<div className='py-5 px-12 md:w-[800px] overflow-y-auto h-screen'>
						<div className='mb-16'>
							<h1 className='changa-bold text-xl capitalize mb-[2px] text-[#454488]'>Your Profile</h1>
						</div>
						<div>
							<div className='flex items-center gap-6 mb-16'>
								<div>
									{isDataFetched ? (
										<img
											className='w-[90px] h-[90px] rounded-full object-cover'
											src={profile.profilepic}
											alt=''
										/>
									) : (
										<Skeleton
											circle={true}
											width={90}
											height={90}
										/>
									)}
								</div>
								<div>
									{/* <h1 className='changa-bold mb-3'>Profile</h1> */}
									{/* <p>Update your photo and personal details</p> */}
								</div>
							</div>
							<hr />
							<div className='mt-11'>
								<div className='flex items-center gap-4'>
									<div>
										<h2 className='font-semibold text-xl'>Personal Information</h2>
									</div>
									<div>
										<div
											onClick={handlePersonalEdit}
											className='px-3 py-1 rounded-2xl bg-white flex items-center gap-2 border border-gray-300 cursor-pointer'>
											<div>
												<img
													src={edit}
													alt=''
												/>
											</div>
											<div>
												<p>Edit</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='md:grid md:grid-cols-2 md:grid-row-2 mt-2 mb-11'>
								<div className='mb-5'>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Full Name</h3>
									{isDataFetched ? (
										<>
											<p>{profile.name}</p>
										</>
									) : (
										<Skeleton width={150} />
									)}
								</div>
								<div className='mb-5'>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Email address</h3>
									{isDataFetched ? <p>{profile.email}</p> : <Skeleton width={190} />}
								</div>
								<div className='mb-5'>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Phone number</h3>
									{isDataFetched ? (
										<>
											<p>{profile.phoneNumber}</p>
										</>
									) : (
										<Skeleton width={120} />
									)}
								</div>
								<div className='mb-5'>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Location</h3>
									{isDataFetched ? (
										<>
											<p>{profile.location}</p>
										</>
									) : (
										<Skeleton width={130} />
									)}
								</div>
							</div>

							<hr />

							<div className='mt-11'>
								<div className='flex items-center gap-4'>
									<div>
										<h2 className='font-semibold text-xl'>Professional Information</h2>
									</div>
									<div>
										<div
											onClick={handleProfessionalEdit}
											className='px-3 py-1 rounded-2xl bg-white flex items-center gap-2 border border-gray-300 cursor-pointer'>
											<div>
												<img
													src={edit}
													alt=''
												/>
											</div>
											<div>
												<p>Edit</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='mt-2'>
								<div>
									<h3 className='text-[#5F5F5F] text-sm mb-1'>Elevator Pitch</h3>
									{isDataFetched ? (
										<>
											<p className='text-[#373737] text-normal font-[500]'>{profile.elevatorpitch}</p>
										</>
									) : (
										<>
											<Skeleton width={650} />
											<Skeleton width={650} />
											<Skeleton width={650} />
											<Skeleton width={650} />
											<Skeleton width={250} />
										</>
									)}
								</div>

								<div className='mt-6'></div>
								<h3 className='text-[#5F5F5F] text-sm mb-1'>Skills</h3>
								<div className='flex gap-2'>
									<div className='w-auto px-4 py-1 bg-white border border-gray-300 capitalize rounded-md font-[500]'>
										{isDataFetched ? <p>{profile.skills.skill1}</p> : <Skeleton width={130} />}
									</div>
									<div className='w-auto px-4 py-1 bg-white border border-gray-300 capitalize rounded-md font-[500]'>
										{isDataFetched ? <p>{profile.skills.skill2}</p> : <Skeleton width={130} />}
									</div>
									<div className='w-auto px-4 py-1 bg-white border border-gray-300 capitalize rounded-md font-[500]'>
										{isDataFetched ? <p>{profile.skills.skill3}</p> : <Skeleton width={130} />}
									</div>
								</div>
							</div>

							<div className='mt-6'>
								<h3 className='text-[#5F5F5F] text-sm mb-1'>Industry</h3>
								{isDataFetched ? (
									<>
										<p>{profile.industry}</p>
									</>
								) : (
									<Skeleton width={100} />
								)}
							</div>

							<div className='mt-6'>
								<h3 className='text-[#5F5F5F] text-sm mb-1'>Portfolio</h3>

								{isDataFetched ? (
									<>
										<a
											href={profile.portfolio}
											target='_blank'>
											<p className='text-purple-500 text-normal font-[500]'>{profile.portfolio}</p>
										</a>
									</>
								) : (
									<Skeleton width={200} />
								)}
							</div>

							<div className='mt-6 mb-11'>
								<h3 className='text-[#5F5F5F] text-sm mb-1'>Resume</h3>
								{isDataFetched ? (
									<a
										href={profile.resume}
										target='_blank'>
										<p className='text-normal font-[500] text-purple-500'>{profile.name}'s resume</p>
									</a>
								) : (
									<Skeleton width={100} />
								)}
							</div>

							<hr />

							<div className='flex gap-5 items-center'>
								{user?.tier === 'Free' ? null : (
									<div className='mt-6'>
										<p
											onClick={handleCancelAccountClick}
											className='text-[#EA4335] font-bold text-lg cursor-pointer bg-transparent border border-[#CFCFCF] py-2 px-4 rounded-lg'>
											Cancel subscription
										</p>
									</div>
								)}
								<div className='mt-6'>
									<p
										onClick={handleDeleteAccountClick}
										className='text-[#fff] font-bold text-lg cursor-pointer bg-[#414080] py-2 px-4 rounded-lg'>
										Delete your Account
									</p>
								</div>
							</div>
						</div>

						{isCancelModalOpen && (
							<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10'>
								<div className='bg-white p-6 rounded-lg shadow-lg relative'>
									<button
										className='absolute right-7 top-5'
										onClick={handleCloseCancelModal}>
										<img
											src={closeSvg}
											alt='Close Icon'
										/>
									</button>
									<h2 className='text-xl font-semibold mb-4 mt-7'>We’re so sorry to see you go</h2>
									<p>
										By cancelling your subscription, your account,
										<br /> your payment subscription bundle and all it offers will be terminated
										<br /> an will be permanently removed from our database.
									</p>
									<div className='bg-[#FEE9ED] px-3 py-2 flex gap-2 mt-7 rounded-md'>
										<div>
											<img
												src={alert}
												alt='sign'
											/>
										</div>
										<div>
											<p>
												Canceling subscription is final and cannot be undone. We will not
												<br /> be able to restore your account
											</p>
										</div>
									</div>
									<div className='mt-9'>
										<button className='px-4 py-2 bg-red-500 text-white rounded-md w-full'>Cancel Subscription</button>
									</div>
								</div>
							</div>
						)}

						{isDeleteModalOpen && (
							<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10'>
								<div className='bg-white p-6 rounded-lg shadow-lg relative'>
									<button
										className='absolute right-7 top-5'
										onClick={handleCloseDeleteModal}>
										<img
											src={closeSvg}
											alt='Close Icon'
										/>
									</button>
									<h2 className='text-xl font-semibold mb-4 mt-7'>We’re so sorry to see you go</h2>
									<p>
										By deleting your account, all your data, including your profile, personal
										<br /> information, history, and saved settings, will be permanently removed
										<br /> from our database.
									</p>
									<div className='bg-[#FEE9ED] px-3 py-2 flex gap-2 mt-7 rounded-md'>
										<div>
											<img
												src={alert}
												alt='sign'
											/>
										</div>
										<div>
											<p>
												Account deletion is final and cannot be undone. We will not
												<br /> be able to restore your account
											</p>
										</div>
									</div>
									<div className='mt-9'>
										<button
											onClick={handleDeleteAccount}
											className='px-4 py-2 bg-red-500 text-white rounded-md w-full'>
											Delete Account
										</button>
									</div>
								</div>
							</div>
						)}
						<AnimatePresence>
							{response && (
								<motion.div
									initial={{ opacity: 0, x: '100%' }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0, x: '100%' }}
									transition={{ duration: 0.3 }}
									className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg z-10'>
									<p>{response}</p>
								</motion.div>
							)}
						</AnimatePresence>
						<AnimatePresence>
							{error && (
								<motion.div
									initial={{ opacity: 0, x: '100%' }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0, x: '100%' }}
									transition={{ duration: 0.3 }}
									className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg z-10'>
									<p>{error}</p>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</Layout>
			)}
		</>
	);
};

export default Profile;
