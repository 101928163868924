import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Landingpage from './pages/Landingpage';
import CreateAccount from './pages/userdomain/CreateAccount';
import Login from './pages/userdomain/Login';
import ForgotPassword from './pages/userdomain/ForgotPassword';
import GetOtp from './pages/userdomain/GetOtp';
import ResetPassword from './pages/userdomain/ResetPassword';
import PasswordSuccess from './pages/userdomain/PasswordSuccess';
import DashboardLanding from './pages/dashboard/DashboardLanding';
import Message from './pages/dashboard/Message';
import Likes from './pages/dashboard/Likes';
import Settings from './pages/dashboard/settings/Settings';
import Questions from './pages/Questions/Questions';
import Profile from './pages/dashboard/settings/Profile';
import Notification from './pages/dashboard/settings/Notification';
import Support from './pages/dashboard/settings/Support';
import { AuthProvider, useAuth } from './hooks/useAuthentication';
import NotFound from './pages/NotFound';
import UploadDp from './pages/userdomain/UploadDp';
import { Spinner } from './pages/dashboard/components/Spinner';
import DeleteSuccessful from './pages/userdomain/DeleteSuccessful';
import EditPersonalDetails from './pages/userdomain/EditPersonalDetails';
import EditProfessionalDetails from './pages/userdomain/EditProfessionalDetails';
import { useProfileData } from './services/getProfileService';
import ExpiredVerificationLink from './pages/userdomain/ExpiredVerificationLink';
import CompleteProfile from './pages/userdomain/CompleteProfile';
import MobileChat from './pages/dashboard/MobileChat';
import EmailVerified from './pages/userdomain/EmailVerified';
import CancelSubscription from './pages/dashboard/subscription/CancelSubscription';
import Terms from './pages/footerPages/Terms';
import Security from './pages/footerPages/Security';
import Privacy from './pages/footerPages/Privacy';
import Referral from './pages/dashboard/settings/Referral';

const ProtectedRoute = ({ element, ...rest }) => {
	const { user, isLoading } = useAuth();
	const { profileData, isLoading: isProfileLoading } = useProfileData();
	console.log(user)

	if (isLoading || isProfileLoading) {
		return <Spinner />;
	}

	if (!user) {
		return (
			<Navigate
				to='/login'
				replace
			/>
		);
	}

	//* checker for isQuestionsAnswered attribute
	if (profileData && profileData?.data && profileData?.data?.isQuestionAnswered === true) {
		console.log(`profileData: ${profileData}`)
		return element;
	} else {
		return (
			<Navigate
				to='/complete-profile'
				replace
			/>
		);
	}

};


function App() {
	return (
		<BrowserRouter>
			<AuthProvider>
				<Routes>
					<Route
						path='/'
						element={
							<Landingpage
								showNav
								showFooter
							/>
						}
					/>
					<Route
						path='/create-account'
						element={<CreateAccount />}
					/>
					<Route
						path='/login'
						element={<Login />}
					/>
					<Route
						path='/forgot-password'
						element={<ForgotPassword />}
					/>
					<Route
						path='/get-otp'
						element={<GetOtp />}
					/>
					<Route
						path='/reset-password'
						element={<ResetPassword />}
					/>
					<Route
						path='/success'
						element={<PasswordSuccess />}
					/>
					<Route
						path='/questions'
						element={<Questions />}
					/>
					<Route
						path='/email-verified'
						element={<EmailVerified />}
					/>
					<Route
						path='/upload-profile-picture'
						element={<UploadDp />}
					/>
					<Route
						path='/security'
						element={<Security />}
					/>
					<Route
						path='/terms'
						element={<Terms />}
					/>
					<Route
						path='/privacy'
						element={<Privacy />}
					/>
					<Route
						path='/dashboard'
						element={<DashboardLanding />}
					/>
					<Route
						path='/delete-success'
						element={<DeleteSuccessful />}
					/>
					<Route
						path='/link_expired'
						element={<ExpiredVerificationLink />}
					/>
					<Route
						path='/complete-profile'
						element={<CompleteProfile />}
					/>
					
					{/* <Route
						path='/dashboard'
						element={<DashboardLanding />}
					/> */}
					<Route
						path='/messages'
						element={<ProtectedRoute element={<Message />} />}
					/>
					<Route
						path='/messages/:userId'
						element={<ProtectedRoute element={<MobileChat />} />}
					/>
					<Route
						path='/likes'
						element={<ProtectedRoute element={<Likes />} />}
					/>
					<Route
						path='/settings'
						element={<ProtectedRoute element={<Settings />} />}/>
					<Route
						path='/profile'
						element={<ProtectedRoute element={<Profile />} />}
					/>
					<Route
						path='/referral'
						element={<ProtectedRoute element={<Referral />} />}
					/>
					<Route
						path='/cancel-subscription'
						element={<ProtectedRoute element={<CancelSubscription />} />}
					/>
					<Route
						path='/edit-personal-information'
						element={<ProtectedRoute element={<EditPersonalDetails />} />}
					/>
					<Route
						path='/edit-professional-information'
						element={<ProtectedRoute element={<EditProfessionalDetails />} />}
					/>
					<Route
						path='*'
						element={<NotFound />}
					/>
				</Routes>
			</AuthProvider>
		</BrowserRouter>
	);
}

export default App;
