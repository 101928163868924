import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import Cookies from 'js-cookie';

import { Label } from './Questions';
import BASE_URL from '../../services/index';
import { fundingStageOptions } from '../../assets/staticdata/fundingStage';
import { industryOptions } from '../../assets/staticdata/industry';
import { skillOptions } from '../../assets/staticdata/skillsOption';
import { locationData } from '../../assets/staticdata/location';

const endpoint = '/auth/additional-information';

const QuestionsYes = () => {
	const haveStartup = 'Yes';
	const email = localStorage.getItem('userEmail');
	const [companyName, setCompanyName] = useState('');
	const [industry, setIndustry] = useState('');
	const [fundingStage, setFundingStage] = useState('');
	const [userLocation, setUserLocation] = useState('');
	const [elevatorPitch, setElevatorPitch] = useState('');
	const [skillDemanded, setSkillDemanded] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [response, setResponse] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const [wordCount, setWordCount] = useState(0);
	const [showFundingStageOptions, setShowFundingStageOptions] = useState(false);
	const [showindustryOptions, setshowindustryOptions] = useState(false);
	const [showSkillsOptions, setShowSkillsOptions] = useState(false);
	const [locationSuggestions, setLocationSuggestions] = useState([]);
	const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);

	useEffect(() => {
		// Get the cookie value directly using js-cookie
		const cookieValue = Cookies.get('hai_auth', { domain: '.helpai.co' });

		console.log('Cookie Value:', cookieValue);

		if (cookieValue) {
			// Decode the cookie value
			const decodedCookie = decodeURIComponent(cookieValue);

			console.log('Decoded Cookie:', decodedCookie);

			// Parse the JSON string to get individual values
			const cookieObject = JSON.parse(decodedCookie);
			const { email, idToken: accessToken } = cookieObject;

			console.log('Email:', email);
			console.log('Access Token:', accessToken);

			// Set cookies using js-cookie
			Cookies.set('userEmail', email);
			Cookies.set('accessToken', accessToken);
			localStorage.setItem('userEmail', cookieObject.email);
		}
	}, []);

	const filterLocationSuggestions = (query) => {
		const filteredSuggestions = locationData.filter((location) =>
			`${location.city}, ${location.country}`.toLowerCase().includes(query.toLowerCase())
		);
		setLocationSuggestions(filteredSuggestions);
	};

	const handleLocationInputChange = (event) => {
		const { value } = event.target;
		setUserLocation(value);
		filterLocationSuggestions(value);
	};

	const handleSelectLocation = (location) => {
		setUserLocation(location);
		setShowLocationSuggestions(false);
	};

	useEffect(() => {
		if (locationSuggestions.length > 0) {
			setShowLocationSuggestions(true);
		} else {
			setShowLocationSuggestions(false);
		}
	}, [locationSuggestions]);

	const toggleFundingStageOptions = () => {
		setShowFundingStageOptions(!showFundingStageOptions);
	};

	const toggleIndustryOptions = () => {
		setshowindustryOptions(!showindustryOptions);
	};

	const handleSelectIndustry = (stage) => {
		setIndustry(stage);
		setshowindustryOptions(false);
	};

	const handleSelectFundingStage = (stage) => {
		setFundingStage(stage);
		setShowFundingStageOptions(false);
	};

	const toggleSkillsOptions = () => {
		setShowSkillsOptions(!showSkillsOptions);
	};

	const handleSelectSkill = (skill) => {
		if (skillDemanded.includes(skill)) {
			setSkillDemanded(skillDemanded.filter((selectedSkill) => selectedSkill !== skill));
		} else if (skillDemanded.length < 3) {
			setSkillDemanded([...skillDemanded, skill]);
		}
		if (skillDemanded.length === 3) {
			setShowSkillsOptions(false);
		}
	};

	const updateWordCount = (value) => {
		const words = value.trim().split(/\s+/);
		setWordCount(words.length);
	};

	const setElevatorPitchValue = (value) => {
		if (value.length <= 400) {
			setElevatorPitch(value);
			updateWordCount(value);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		//const formattedSkills = skillDemanded[0].split(',').map((skill) => skill.trim());

		setIsSubmitting(true);

		const data = {
			email,
			haveStartup,
			companyName,
			industry,
			fundingStage,
			userLocation,
			elevatorPitch,
			skillDemanded,
		};

		console.log(email);
		console.log(data);

		axios
			.post(BASE_URL + endpoint, data)
			.then((response) => {
				console.log(response.data.Message);
				setCompanyName('');
				setIndustry('');
				setFundingStage('');
				setUserLocation('');
				setElevatorPitch('');
				setSkillDemanded([]);
				setResponse(response.data.Message);
				setIsSubmitting(false);
				setTimeout(() => {
					navigate('/upload-profile-picture', { replace: true });
				}, 5000);
			})
			.catch((error) => {
				console.error(error);
				// setCompanyName('');
				// setIndustry('');
				// setFundingStage('');
				// setUserLocation('');
				// setElevatorPitch('');
				// setSkillDemanded([]);
				setIsSubmitting(false);
				setError("Email not verified, Kindly verify your email");
				setIsSubmitting(false);
				setTimeout(() => {
					setError('');
				}, 9000);
			});
	};

	const isFormValid =
		companyName && industry && fundingStage && userLocation && elevatorPitch && skillDemanded;

	return (
		<>
			{isTabletOrMobile ? (
				<>
					<form
						className='mb-6'
						onSubmit={handleSubmit}>
						<Label
							html='Company name'
							title='Company name'
						/>
						<input
							required
							type='text'
							value={companyName}
							onChange={(e) => setCompanyName(e.target.value)}
							placeholder='Flutterwave'
							className='bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5'
						/>
						<Label
							html='Industry'
							title='Industry'
						/>
						<button
							type='button'
							onClick={toggleIndustryOptions}
							className={clsx([
								isTabletOrMobile ? 'w-full' : 'w-[417px]',
								'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
							])}>
							{industry || 'Select Industry'}
							<span className='ml-2'>
								{/* Custom arrow icon */}
								<svg
									className={clsx(['w-5 h-5', showindustryOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 9l-7 7-7-7'
									/>
								</svg>
							</span>
						</button>
						{/*  */}
						{showindustryOptions && (
							<div
								className={clsx([
									'absolute z-10 top-[470px] w-full mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg',
								])}>
								{industryOptions.map((stage) => (
									<button
										key={stage}
										type='button'
										onClick={() => handleSelectIndustry(stage)}
										className={clsx(['w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md'])}>
										{stage}
									</button>
								))}
							</div>
						)}
						<Label
							html='Funding stage'
							title='Funding stage'
						/>
						<button
							type='button'
							onClick={toggleFundingStageOptions}
							className={clsx([
								isTabletOrMobile ? 'w-full' : 'w-[417px]',
								'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
							])}>
							{fundingStage || 'Select Funding Stage'}
							<span className='ml-2'>
								{/* Custom arrow icon */}
								<svg
									className={clsx(['w-5 h-5', showFundingStageOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 9l-7 7-7-7'
									/>
								</svg>
							</span>
						</button>
						{/* Custom dropdown */}
						{showFundingStageOptions && (
							<div
								className={clsx([
									'absolute z-10 top-[570px] w-auto mt-1 py-3 bg-white border border-[#E6E6E6] rounded-lg',
								])}>
								{fundingStageOptions.map((stage) => (
									<button
										key={stage}
										type='button'
										onClick={() => handleSelectFundingStage(stage)}
										className={clsx(['w-full p-2 text-left hover:bg-[#F5F5FF] rounded-md'])}>
										{stage}
									</button>
								))}
							</div>
						)}
						<Label
							html='Location'
							title='Location'
						/>
						<input
							required
							type='text'
							value={userLocation}
							onChange={handleLocationInputChange}
							placeholder='Search your location'
							className='bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5'
						/>
						{showLocationSuggestions && (
							<div className='absolute z-10 top-[700px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[200px] overflow-y-auto'>
								<ul className=''>
									{locationSuggestions.map((suggestion, index) => (
										<li
											className='w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md cursor-pointer'
											key={index}
											onClick={() => handleSelectLocation(`${suggestion.city}, ${suggestion.country}`)}>
											{suggestion.city}, {suggestion.country}
										</li>
									))}
								</ul>
							</div>
						)}
						<Label
							html='Elevator pitch about your startup'
							title='Elevator pitch about your startup'
						/>
						<textarea
							required
							type='text'
							value={elevatorPitch}
							onChange={(e) => setElevatorPitchValue(e.target.value)}
							placeholder='Talk about your startup/company'
							className='bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3'
						/>
						<p className='mb-5'>{`${elevatorPitch.length}/400 characters`}</p>
						<Label
							html='What skills are you looking for in a co-founder'
							title='What skills are you looking for in a co-founder'
						/>
						<button
							type='button'
							onClick={toggleSkillsOptions}
							className={clsx([
								isTabletOrMobile ? 'w-full' : 'w-[417px]',
								'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
							])}>
							{skillDemanded.length === 0
								? 'Select Desired skills'
								: skillDemanded.slice(0, 3).map((selectedSkill, index) => (
										<span
											key={index}
											className='bg-purple-500 text-white rounded-full px-2 py-1 mr-2 flex items-center'>
											{selectedSkill}
											<button
												type='button'
												className='ml-2 text-[#AFAFAF] hover:text-[#414080]'
												onClick={() => handleSelectSkill(selectedSkill)}>
												X
											</button>
										</span>
								  ))}
							{skillDemanded.length > 3 && <span className='ml-2'>+{skillDemanded.length - 3}</span>}
							<span className='ml-2'>
								{/* Custom arrow icon */}
								<svg
									className={clsx(['w-5 h-5', showSkillsOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 9l-7 7-7-7'
									/>
								</svg>
							</span>
						</button>

						{/*  */}
						{showSkillsOptions && (
							<div className='absolute top-[930px] z-10 w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[140px] overflow-y-auto'>
								{skillOptions.map((skill) => (
									<button
										key={skill}
										type='button'
										onClick={() => handleSelectSkill(skill)}
										className={clsx([
											'w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md',
											skillDemanded.includes(skill) && 'bg-[#F5F5FF] text-[#414080] font-semibold', // Highlight selected skills
										])}>
										{skill}
									</button>
								))}
							</div>
						)}

						<button
							className={clsx([
								'w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]',
								{
									'cursor-not-allowed opacity-50': !isFormValid,
								},
							])}
							disabled={!isFormValid || isSubmitting}>
							{isSubmitting ? 'Submitting...' : 'Continue'}
						</button>
					</form>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-md px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-md px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</>
			) : (
				<>
					<form
						onSubmit={handleSubmit}
						className='flex flex-col mb-[25px]'>
						<Label
							html='Company name'
							title='Company name'
						/>
						<input
							type='text'
							value={companyName}
							onChange={(e) => setCompanyName(e.target.value)}
							id='Company name'
							placeholder='Flutterwave'
							className={clsx([
								'w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF]',
							])}
						/>
						{/*  */}
						<div className='flex flex-col'>
							<Label
								html='Industry'
								title='Industry'
							/>
							<button
								type='button'
								onClick={toggleIndustryOptions}
								className={clsx([
									isTabletOrMobile ? 'w-full' : 'w-[417px]',
									'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
								])}>
								{industry || 'Select Industry'}
								<span className='ml-2'>
									{/* Custom arrow icon */}
									<svg
										className={clsx(['w-5 h-5', showindustryOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={2}
											d='M19 9l-7 7-7-7'
										/>
									</svg>
								</span>
							</button>
							{/*  */}
							{showindustryOptions && (
								<div
									className={clsx([
										'absolute z-10 top-[390px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg',
									])}>
									{industryOptions.map((stage) => (
										<button
											key={stage}
											type='button'
											onClick={() => handleSelectIndustry(stage)}
											className={clsx(['w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md'])}>
											{stage}
										</button>
									))}
								</div>
							)}
						</div>
						{/*  */}
						<Label
							html='Funding Stage'
							title='Funding Stage'
						/>
						<button
							type='button'
							onClick={toggleFundingStageOptions}
							className={clsx([
								isTabletOrMobile ? 'w-full' : 'w-[417px]',
								'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
							])}>
							{fundingStage || 'Select Funding Stage'}
							<span className='ml-2'>
								{/* Custom arrow icon */}
								<svg
									className={clsx(['w-5 h-5', showFundingStageOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 9l-7 7-7-7'
									/>
								</svg>
							</span>
						</button>
						{/* Custom dropdown */}
						{showFundingStageOptions && (
							<div
								className={clsx([
									'absolute z-10 top-[496px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg',
								])}>
								{fundingStageOptions.map((stage) => (
									<button
										key={stage}
										type='button'
										onClick={() => handleSelectFundingStage(stage)}
										className={clsx(['w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md'])}>
										{stage}
									</button>
								))}
							</div>
						)}
						{/*  */}
						{/*  */}
						<Label
							html='Location'
							title='Location'
						/>
						<input
							type='text'
							id='Location'
							value={userLocation}
							onChange={handleLocationInputChange}
							placeholder='Search your location'
							className={clsx([
								'w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF]',
							])}
						/>
						{showLocationSuggestions && (
							<div className='absolute z-10 top-[600px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[200px] overflow-y-auto'>
								<ul className=''>
									{locationSuggestions.map((suggestion, index) => (
										<li
											className='w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md cursor-pointer'
											key={index}
											onClick={() => handleSelectLocation(`${suggestion.city}, ${suggestion.country}`)}>
											{suggestion.city}, {suggestion.country}
										</li>
									))}
								</ul>
							</div>
						)}
						{/*  */}
						<Label
							html='Elevator Pitch'
							title='Elevator pitch about your startup'
						/>
						<textarea
							type='text'
							id='Elevator Pitch'
							value={elevatorPitch}
							onChange={(e) => setElevatorPitchValue(e.target.value)}
							placeholder='Talk about your startup/company'
							className={clsx([
								'w-[417px] h-[153px] rounded-[8px] inputbox p-3 mt-[8px] border-[1.03915px] border-[#AFAFAF]',
							])}
						/>
						<p className='mb-5 mt-0'>{`${elevatorPitch.length}/400 characters`}</p>
						{/*  */}
						<div className='flex flex-col'>
							<Label
								html='Skills'
								title='What skills are you looking for in a co-founder?'
							/>
							<button
								type='button'
								onClick={toggleSkillsOptions}
								className={clsx([
									isTabletOrMobile ? 'w-full' : 'w-[417px]',
									'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
								])}>
								{skillDemanded.length === 0
									? 'Select Desired skills'
									: skillDemanded.slice(0, 3).map((selectedSkill, index) => (
											<span
												key={index}
												className='bg-purple-500 text-white rounded-full px-2 py-1 mr-2 flex items-center'>
												{selectedSkill}
												<button
													type='button'
													className='ml-2 text-[#AFAFAF] hover:text-[#414080]'
													onClick={() => handleSelectSkill(selectedSkill)}>
													X
												</button>
											</span>
									  ))}
								{skillDemanded.length > 3 && <span className='ml-2'>+{skillDemanded.length - 3}</span>}
								<span className='ml-2'>
									{/* Custom arrow icon */}
									<svg
										className={clsx(['w-5 h-5', showSkillsOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={2}
											d='M19 9l-7 7-7-7'
										/>
									</svg>
								</span>
							</button>

							{/*  */}
							{showSkillsOptions && (
								<div className='absolute top-[935px] z-10 w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[140px] overflow-y-auto'>
									{skillOptions.map((skill) => (
										<button
											key={skill}
											type='button'
											onClick={() => handleSelectSkill(skill)}
											className={clsx([
												'w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md',
												skillDemanded.includes(skill) && 'bg-[#F5F5FF] text-[#414080] font-semibold', // Highlight selected skills
											])}>
											{skill}
										</button>
									))}
								</div>
							)}
						</div>
						{/* button */}
						<button
							className={clsx([
								'w-[419px] h-[49px] bg-[#414080] text-white font-semibold rounded-lg',
								{
									'cursor-not-allowed opacity-50': !isFormValid || isSubmitting,
								},
							])}
							disabled={!isFormValid || isSubmitting}>
							{isSubmitting ? 'Submitting...' : 'Submit'}
						</button>
					</form>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</>
			)}
		</>
	);
};

export default QuestionsYes;
