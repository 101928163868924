import { useState, useEffect } from 'react';
import axios from 'axios';

import BASE_URL from './index.js';
import useProfile from '../hooks/useProfile.js';
import useUser from '../hooks/useUser.jsx';

const fetchProfileData = async (url, authToken) => {
	const headers = {
		Authorization: `Bearer ${authToken}`,
	};

	try {
		const response = await axios.get(url, { headers });
		return response.data;
	} catch (error) {
		throw new Error('Failed to fetch profile data');
	}
};

export const useProfileData = () => {
	const { setProfile } = useProfile();
	const { setUser } = useUser();

	const getAuthTokenFromLocalDb = () => {
		const authTokenWithQuotes = localStorage.getItem('responseData');
		return authTokenWithQuotes ? authTokenWithQuotes.replace(/^"(.*)"$/, '$1') : null;
	};

	const authToken = getAuthTokenFromLocalDb();
	console.log(authToken);

	const url = BASE_URL + `/account/settings/profile`;

	const [profileData, setProfileData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [ setTier] = useState();

	useEffect(() => {
		if (!authToken) {
			setIsError(true);
			setIsLoading(false);
			return;
		}

		fetchProfileData(url, authToken)
			.then((data) => {
				const fetchedTier = data.data.tier;
				setTier(fetchedTier);
				setUser((prev) => ({ ...prev, tier: fetchedTier }));
				setProfileData(data);
				setProfile(data);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsError(true);
				setIsLoading(false);
			});
	}, [url, authToken, setUser, setProfile]);

	return {
		profileData,
		isLoading,
		isError,
	};
};