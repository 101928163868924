import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const useAuthentication = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem('responseData');
    const loginTime = localStorage.getItem('loginTime');

    if (userData && loginTime) {
      try {
        const parsedData = JSON.parse(userData);
        setUser(parsedData);

        const currentTime = new Date().getTime();
        const loginTimestamp = parseInt(loginTime);

        // Calculate the elapsed time since login in milliseconds
        const elapsedTime = currentTime - loginTimestamp;

        // Define the logout time (50 minutes in milliseconds)
        const logoutTime = 50 * 60 * 1000;

        // Check if the elapsed time is greater than or equal to the logout time
        if (elapsedTime >= logoutTime) {
          logout(); // Automatically log out if 50 minutes have passed
          return;
        }
      } catch (error) {
        console.error('Error parsing user data from local storage:', error);
      }
    } else {
      setUser(null);
    }

    setIsLoading(false);
  }, []);

  const login = (userData) => {
    setUser(userData);

    // Store the login timestamp in local storage
    const loginTime = new Date().getTime();
    localStorage.setItem('loginTime', loginTime);

    localStorage.setItem('responseData', JSON.stringify(userData));
  };

  const logout = () => {
    setUser(null);
    navigate("/");
    localStorage.removeItem('responseData');
    localStorage.removeItem('loginTime'); // Remove the login timestamp
  };

  return { user, login, logout, isLoading };
};

export const AuthProvider = ({ children }) => {
  const auth = useAuthentication();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
