import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from '../../../services/index.js';
import useUser from '../../../hooks/useUser';
import { PaystackButton } from 'react-paystack';

// images and icons
import heart from '../../../assets/images/love.svg';

const CountdownTimer = () => {
	const { user } = useUser();
	const publicKey = 'pk_live_7667f083a3fca566fb1a7e32c79f2eb9bb8b17d3';
	const plan = 'PLN_vispnpi0vpiorni';
	const amount = 1000000;
	const [name, setName] = useState(user.name);
	const [phone, setPhone] = useState(user.phone);
	const [email, setEmail] = useState(user.useremail);
	const [timeRemaining, setTimeRemaining] = useState(0);

	const handlePaystackSuccessAction = (reference) => {
		window.location.reload();
		console.log(reference);
	};

	const handlePaystackCloseAction = () => {
		console.log('closed');
	};

	const componentProps = {
		reference: new Date().getTime().toString(),
		email,
		amount,
		metadata: {
			name,
			phone,
		},
		channels: ['card', 'qr', 'ussd', 'mobile_money', 'eft', 'bank_transfer', 'payattitude'],
		publicKey,
		plan,
		text: 'Upgrade',
		onSuccess: (reference) => handlePaystackSuccessAction(reference),
		onClose: handlePaystackCloseAction,
	};

	useEffect(() => {
		const interval = setInterval(() => {
			calculateTimeRemaining();
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const calculateTimeRemaining = () => {
		const currentTime = Date.now();
		const lastRequestTime = user.lastRequestTime;
		const timeDifference = currentTime - lastRequestTime;
		const twelveHoursInMillis = 12 * 60 * 60 * 1000;
		const remainingTime = twelveHoursInMillis - timeDifference;
		setTimeRemaining(remainingTime > 0 ? remainingTime : 0);
	};

	const formatTime = (milliseconds) => {
		const hours = Math.floor(milliseconds / (1000 * 60 * 60));
		const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
			.toString()
			.padStart(2, '0')}`;
	};

	return (
		<div className='text-center md:w-[500px] p-5'>
			<h1 className='text-[#0F0F0F] text-3xl text-center mb-2'>Uh oh!, you’re out of free likes</h1>
			<p className='text-[#5F5F5F] text-sm mb-20'>
				Come back in 12 hours for more likes so you can continue viewing profiles
			</p>
			<div className='py-8 px-3 bg-white shadow rounded-md'>
				<p className='text-[#414080] text-5xl'>{formatTime(timeRemaining)}</p>
			</div>
			<p className='text-[#5F5F5F] text-sm mt-20 mb-7'>
				You can skip the wait by upgrading to a premium account!
			</p>
			<div className='flex justify-center items-center'>
				<div className='flex gap-3 items-center'>
					<div>
						<PaystackButton
							className='bg-[#414080] px-9 py-2.5 text-white changa rounded-lg cursor-pointer hover:bg-[#5958ae] duration-500 transition-all'
							{...componentProps}
						/>
					</div>
					<div className=''>
						<div className='flex gap-2 items-center border border-[#414080] rounded-lg'>
							<div>
								<img
									src={love}
									alt='heart icon'
								/>
							</div>
							<div>0</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CountdownTimer;
