import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { PaystackButton } from 'react-paystack';

import Layout from './layout.jsx';
import MobileHeader from './components/MobileHeader.jsx';
import likeslocked from '../../assets/images/likes0.svg';
import useUser from '../../hooks/useUser.jsx';
import BASE_URL from '../../services/index.js';
import personalpic from '../../assets/images/User profile picture.svg';
import locationicon from '../../assets/images/location icon.svg';
import partner from '../../assets/images/partner icon.svg';
import rewind from '../../assets/images/Rewind button.svg';
import dislike from '../../assets/images/Dislike button.svg';
import like from '../../assets/images/Like button.svg';
import profile from '../../assets/images/person.svg';
import levelup from '../../assets/images/levelup.svg';
import close from '../../assets/images/close-modal.svg';
import { Spinner } from './components/Spinner';
import { SkeletonLoader } from './components/SkeletonLoader.jsx';
import zerolikes from '../../assets/images/zerolikes.svg';

const Likes = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState('People who liked you');
	const options = ['People who liked you', 'People you liked', 'People you disliked'];

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setIsOpen(false);
	};

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const renderSelectedComponent = () => {
		switch (selectedOption) {
			case 'People who liked you':
				return <WhoLikedMe />;
			case 'People you liked':
				return <WhoILiked />;
			case 'People you disliked':
				return <WhoIDisliked />;
			default:
				return null;
		}
	};

	return (
		<>
			{isTabletOrMobile ? (
				<div className='bg-[#FAFBFD] px-2 py-14'>
					<MobileHeader />
					<div className='mb-8'>
						<h1 className='changa-bold text-xl mb-[2px] text-black'>Prospects</h1>
						<p className='font-normal text-base text-black'>
							Discover those who've shown interest in your profile
						</p>
					</div>
					<div>
						<div
							className='px-5 py-2.5 border border-[#454488] rounded-lg mb-1 transition-all duration-500 cursor-pointer'
							onClick={toggleDropdown}>
							{selectedOption}
						</div>
						{isOpen && (
							<div className='bg-white border border-[#454488] rounded-lg transition-all duration-500 cursor-pointer '>
								{options.map((option, index) => (
									<div
										key={index}
										className='px-4 py-2 hover:bg-gray-200 transition-all duration-300'
										onClick={() => handleOptionClick(option)}>
										{option}
									</div>
								))}
							</div>
						)}
					</div>
					<div>{renderSelectedComponent()}</div>
				</div>
			) : (
				<Layout>
					<div className='px-9 py-5 w-full'>
						<div className='mb-10 flex flex-grow justify-between items-center'>
							<div>
								<h1 className='changa-bold text-xl mb-[2px] text-[#454488]'>Prospects</h1>
								<p className='font-normal text-base text-[#999999]'>
									Discover those who've shown interest in your profile
								</p>
							</div>
							<div>
								<div
									className='px-5 py-2.5 border border-[#454488] rounded-lg mb-1 transition-all duration-500 cursor-pointer'
									onClick={toggleDropdown}>
									{selectedOption}
								</div>
								{isOpen && (
									<div className='bg-white border border-[#454488] rounded-lg transition-all duration-500 cursor-pointer '>
										{options.map((option, index) => (
											<div
												key={index}
												className='px-4 py-2 hover:bg-gray-200 transition-all duration-300'
												onClick={() => handleOptionClick(option)}>
												{option}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
						<div className='mb-10'>{renderSelectedComponent()}</div>
					</div>
				</Layout>
			)}
		</>
	);
};

export default Likes;

const WhoLikedMe = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const { user } = useUser();
	const [likedUsers, setLikedUsers] = useState([]);
	const [shouldDisplay, setShouldDisplay] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await axios.get(BASE_URL + `/account/wholikedme`, {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				});
				const data = response.data;
				setLikedUsers(data.data);
				setShouldDisplay(data.shouldDisplay);
				// console.log(data);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		};
		fetchData();
	}, []);

	const openModal = () => {
		setIsModalOpen(true);
	};

	return (
		<div>
			{loading ? (
				<div className='h-[700px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 md:px-5'>
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
				</div>
			) : likedUsers.length > 0 ? (
				<div className='h-[700px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 px-7 md:px-5'>
					{likedUsers.map((user) => (
						<Card
							key={user.id}
							user={user}
							shouldDisplay={shouldDisplay}
							openModal={openModal}
						/>
					))}
				</div>
			) : (
				<div className='flex justify-center items-center py-5'>
					<NoLikesYet />
				</div>
			)}
			{isModalOpen && <ProspectModal onClose={() => setIsModalOpen(false)} />}
		</div>
	);
};

const WhoILiked = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const { user } = useUser();
	const [likedUsers, setLikedUsers] = useState([]);
	const [shouldDisplay, setShouldDisplay] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await axios.get(BASE_URL + `/account/people-i-liked`, {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				});
				const data = response.data;
				// console.log(data);
				setLikedUsers(data.data);
				setShouldDisplay(data.shouldDisplay);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		};
		fetchData();
	}, []);

	const openModal = () => {
		setIsModalOpen(true);
	};

	return (
		<div>
			{loading ? (
				<div className='h-[700px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 md:px-5'>
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
				</div>
			) : likedUsers.length > 0 ? (
				<div className='h-[700px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 px-7 md:px-5'>
					{likedUsers.map((user) => (
						<Card
							key={user.id}
							user={user}
							shouldDisplay={shouldDisplay}
							openModal={openModal}
						/>
					))}
				</div>
			) : (
				<div className='flex justify-center items-center py-5'>
					<NoLikesYet />
				</div>
			)}
			{isModalOpen && <ProspectModal onClose={() => setIsModalOpen(false)} />}
		</div>
	);
};

const WhoIDisliked = () => {
	const [loading, setLoading] = useState(false);
	const { user } = useUser();
	const [likedUsers, setLikedUsers] = useState([]);
	const [shouldDisplay, setShouldDisplay] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await axios.get(BASE_URL + `/account/people-i-disliked`, {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				});
				const data = response.data;
				// console.log(data);
				setLikedUsers(data.data);
				setShouldDisplay(data.shouldDisplay);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				connsole.log(error);
			}
		};
		fetchData();
	}, []);

	const openModal = () => {
		setIsModalOpen(true);
	};

	return (
		<div>
			{loading ? (
				<div className='h-[700px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 md:px-5'>
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
				</div>
			) : likedUsers.length > 0 ? (
				<div className='h-[700px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 px-7 md:px-5'>
					{likedUsers.map((user) => (
						<Card
							key={user.id}
							user={user}
							shouldDisplay={shouldDisplay}
							openModal={openModal}
						/>
					))}
				</div>
			) : (
				<div className='flex justify-center items-center py-5'>
					<NoLikesYet />
				</div>
			)}
			{isModalOpen && <ProspectModal onClose={() => setIsModalOpen(false)} />}
		</div>
	);
};

const Card = ({ user, shouldDisplay, openModal }) => {
	const [showFullElevatorPitch, setShowFullElevatorPitch] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleToggleElevatorPitch = () => {
		setShowFullElevatorPitch(!showFullElevatorPitch);
	};

	const handleCardClick = () => {
		if (!shouldDisplay) {
			openModal();
		}
	};

	const elevatorPitchToShow = showFullElevatorPitch
		? user?.elevatorPitch
		: user?.elevatorPitch?.slice(0, 100) + '...';

	return (
		<div
			onClick={handleCardClick}
			className={`${
				shouldDisplay
					? 'bg-white w-[340px] md:w-[431px]  rounded-lg px-8 pt-11 pb-11 h-auto mt-10 here'
					: 'bg-white w-[340px] md:w-[431px] rounded-lg px-8 pt-11 pb-11 mb-16 h-auto blur cursor-pointer here'
			}`}>
			{/*  */}

			<div className='flex items-center gap-4 mb-2'>
				<div>
					<img
						className='w-[66px] h-[66px] rounded-full'
						src={user.profileImage}
						alt='profile pic'
					/>
				</div>
				<div className=''>
					<div className='mb-1.5'>
						<p className='changa-bold tracking-wide capitalize text-base text-[#0F0F0F]'>{user.fullName}</p>
					</div>
					<div className='flex items-center gap-1'>
						<div>
							<img
								src={locationicon}
								alt='location-icon'
							/>
						</div>
						<div>
							<p className='capitalize'>{user.userLocation}</p>
						</div>
					</div>
				</div>
			</div>
			{/*  */}
			<div className='flex items-center justify-center gap-2 mb-7'>
				{user.skillDemanded &&
					Array.isArray(user.skillDemanded) &&
					user.skillDemanded.map((skill, index) => (
						<div
							key={index}
							className='bg-[#FFF0FC] text-[#99007A] px-4 py-2 rounded-md capitalize text-sm w-[90px]'
							style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
							{skill.length > 10 ? `${skill.slice(0, 10)}...` : skill}
						</div>
					))}
			</div>
			{/*  */}
			<div>
				<div className='flex items-center gap-2 mb-6'>
					<div>
						<img
							src={partner}
							alt='partner icon'
						/>
					</div>
					<div>
						<p>I’m looking to partner with someone’s idea</p>
					</div>
				</div>
				{/*  */}
				<div className='mb-8'>
					<h1 className='changa-bold text-[#0F0F0F] text-sm'>Elevator Pitch</h1>
					<p className=''>{elevatorPitchToShow}</p>
					{user?.elevatorPitch?.length > 100 && (
						<button
							onClick={handleToggleElevatorPitch}
							className='text-gray-400 hover:underline'>
							{showFullElevatorPitch ? 'Read Less' : 'Read More'}
						</button>
					)}
				</div>
				{/*  */}
				<div className='flex justify-center items-center gap-8'>
					<motion.div
						className='cursor-pointer'
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}>
						<img
							src={like}
							alt=''
						/>
					</motion.div>
					<motion.div
						className='cursor-pointer'
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}>
						<img
							src={profile}
							alt=''
						/>
					</motion.div>
					<motion.div
						className='cursor-pointer'
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}>
						<img
							src={dislike}
							alt=''
						/>
					</motion.div>
				</div>
			</div>
		</div>
	);
};

const ProspectModal = ({ onClose }) => {
	const { user } = useUser();
	const publicKey = 'pk_live_7667f083a3fca566fb1a7e32c79f2eb9bb8b17d3';
	const plan = 'PLN_vispnpi0vpiorni';
	const amount = 1000000;
	const [name, setName] = useState(user.name);
	const [phone, setPhone] = useState(user.phone);
	const [email, setEmail] = useState(user.useremail);

	// const handleMakePayment = () => {
	// 	axios
	// 		.post(
	// 			BASE_URL + `/transaction/make-payment`,
	// 			{},
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${user.token}`,
	// 				},
	// 			}
	// 		)
	// 		.then((response) => {
	// 			// console.log('Successful:', response.data);
	// 			if (response.data?.data?.checkout_url) {
	// 				window.open(response.data?.data?.checkout_url, '_blank');
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error making payment:', error);
	// 		});
	// };

	const handlePaystackSuccessAction = (reference) => {
		window.location.reload();
		console.log(reference);
	};

	const handlePaystackCloseAction = () => {
		console.log('closed');
	};

	const componentProps = {
		reference: new Date().getTime().toString(),
		email,
		amount,
		metadata: {
			name,
			phone,
		},
		channels: ['card', 'qr', 'ussd', 'mobile_money', 'eft', 'bank_transfer', 'payattitude'],
		publicKey,
		plan,
		text: 'Upgrade',
		onSuccess: (reference) => handlePaystackSuccessAction(reference),
		onClose: handlePaystackCloseAction,
	};

	return (
		<div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50'>
			<div className='bg-white p-6 rounded-lg md:w-[431px]'>
				<div className='flex justify-end'>
					<button onClick={onClose}>
						<img
							src={close}
							alt='close icon'
						/>
					</button>
				</div>

				<>
					<div className='flex flex-col justify-center items-center'></div>
					<div className='flex justify-center items-center mb-8'>
						<img
							src={levelup}
							alt='bod'
						/>
					</div>
					<h1 className='text-center text-[#454488] text-3xl changa-semibold mb-2'>
						Level up your experience!
					</h1>
					<p className='text-[#5F5F5F] text-base mb-10 text-center'>
						Upgrade to Premium to discover who liked your profile.
					</p>
					<div className='flex justify-center items-center'>
						{/* <button
							onClick={handleMakePayment}
							className='px-20 py-2 text-white bg-[#414080] rounded-md'>
							Upgrade
						</button> */}
						<PaystackButton
							className='bg-[#414080] px-9 py-2.5 text-white changa rounded-lg cursor-pointer hover:bg-[#5958ae] duration-500 transition-all'
							{...componentProps}
						/>
					</div>
				</>
			</div>
		</div>
	);
};

const NoLikesYet = () => {
	return (
		<div className='flex flex-col items-center '>
			<div classsName=''>
				<img
					src={zerolikes}
					alt='0 heart avatar'
				/>
			</div>
			<div>
				<h1 className='text-center text-3xl text-[#454488] mb-4'>No likes yet.</h1>
			</div>
			<div>
				<p className='text-center text-[#555555] text-base'>
					Stay active and complete your profile to attract likes
					<br /> from others. Your first like could be just a click away!
				</p>
			</div>
		</div>
	);
};
