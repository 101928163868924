import { useParams } from 'react-router-dom';

import { ChatContainer } from "./Message"

const MobileChat = () => {
  const { userId } = useParams();
  return (
    <div>
      <ChatContainer currentChatUser={{ _id: userId }} />
    </div>
  )
}

export default MobileChat;